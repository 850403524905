/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import PinInput from 'react-pin-input';

function VerifyInput({ ...props }) {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current.focus();
  }, []);

  return (
    <div>
      <PinInput
        {...field}
        {...props}
        ref={inputRef}
        length={6}
        type="numeric"
        onComplete={(e) => setValue(e)}
        inputMode="number"
        style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
        inputStyle={{
          borderRadius: '4px',
          border: '1px solid var(--bs-gray-400-ced-4-da, #CED4DA)',
          background: '#FFF',
          height: 32,
          width: 41
        }}
        inputFocusStyle={{
          boxShadow: 'inset 0px 0px 0px 3px rgba(var(--primary-rgb), 0.2)',
          outline: '0 none',
          border: '1px solid var (--primary)',
          borderColor: 'var(--primary)'
        }}
        autoSelect={true}
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">
          {t(meta.error)}
        </div>
      ) : null}
    </div>

  );
}

export default VerifyInput;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import intlTelInput from 'intl-tel-input';

function PhoneInput({ ...props }) {
	const { t } = useTranslation();
	const [field, meta, helpers] = useField(props);
	const { setValue } = helpers;
	const { value } = meta;
	const phoneInput = useRef(null);

	useEffect(() => {
		intlTelInput(phoneInput.current, {
			utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
			initialCountry: "tr",
			onlyCountries: ["tr"],
			dropdownContainer: document.getElementById('phone-input-container')
		});
	}, []);

	useEffect(() => {
		if (value !== undefined) {
			let { length } = value;
			if (length === 4 && value.charAt(length - 1) !== " ") {
				setValue(value.slice(0, 3) + " " + value.slice(3, length));
			}

			if (length === 8 && value.charAt(length - 1) !== " ") {
				setValue(value.slice(0, 7) + " " + value.slice(7, length));
			}

			if (length === 11 && value.charAt(length - 1) !== " ") {
				setValue(value.slice(0, 10) + " " + value.slice(10, length));
			}
		}
	}, [value]);

	const handlePaste = (event) => {
		event.preventDefault();
		const pastedNumber = event.clipboardData.getData('text');
		const result = pastedNumber.replace(/[- )(]/g, '');
		let formattedNumber = "";

		if (result.startsWith('+90')) {
			formattedNumber = result.substring(3);
		} else if (result.startsWith('0')) {
			formattedNumber = result.substring(1);
		} else {
			formattedNumber = result;
		}

		formattedNumber = `${formattedNumber.slice(0, 3)} ${formattedNumber.slice(3, 6)} ${formattedNumber.slice(6, 8)} ${formattedNumber.slice(8, 12)}`;
		setValue(formattedNumber);
	};

	return (
		<div id="phone-input-container" style={{ position: 'relative' }}>
			<input
				ref={phoneInput}
				className={`form-control ${meta.touched && meta.error && 'is-invalid'}`}
				style={{ width: 294 }}
				maxLength="13"
				type="tel"
				autoComplete='on'
				onPaste={handlePaste}
				{...props}
				{...field}
				inputMode="tel"
				onChange={(e) => {
					setValue(e.target.value.replace(/[^\d\s+#*]/g, ""))
				}}
			/>
			{
				meta.touched && meta.error ? (
					<div className="invalid-feedback">
						{t(meta.error)}
					</div>
				) : null
			}
		</div>
	);
}

export default PhoneInput;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loading from '../loading';
import SmsLogin from '../smsLogin';
import SmsVerification from '../smsVerification';
import SignInButtons from '../signInButtons';
import { setError } from '../../core/slice';
import WeollAlert from '../../components/weoll-alert';

function Login() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentScreen = useSelector(state => state.projectData.currentScreen);
	const error = useSelector(state => state.projectData.error);

	useEffect(() => {
		if (error !== '') {
			const timeout = setTimeout(() => dispatch(setError('')), 10000);
			return () => clearInterval(timeout);
		}
	}, [error]);

	return (
		<>
			{currentScreen === 'loading' && <Loading headerText={t('WELCOME')} subText={t('JUST_A_FEW_SECONDS')} emoji />}
			{currentScreen === 'smsLogin' && <SmsLogin />}
			{currentScreen === 'smsVerification' && <SmsVerification />}
			{currentScreen === 'signInButtons' && <SignInButtons />}
			<WeollAlert status="error" message={error} />
		</>
	);
}

export default Login;

import React, { useRef, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import firebase from 'firebase/compat/app';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import VerifyInput from './subComponents/verifyInput';
import { setCurrentScreen, setphoneConfirm } from '../../core/slice';
import HeaderText from '../../components/headerText';
import SubHeaderText from '../../components/subHeaderText';
import WeollAlert from '../../components/weoll-alert';
import ArrowLeft from '../../assets/images/icons/arrowLeft.svg';
import ResendIcon from '../../assets/images/icons/resendIcon.svg';
import Loading from '../loading';
import errorHandle from '../../utilities/errorHandle';

function SmsVerification() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const phoneConfirm = (useSelector((state) => state.projectData.phoneConfirm));
	const phoneNumber = (useSelector((state) => state.projectData.phoneNumber));
	const pageLoading = useSelector((state) => state.projectData.loading);
	const auth = (useSelector((state) => state.projectData.auth));
	const formRef = useRef(null);
	const resendRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [recaptcha, setRecaptcha] = useState(null);
	const [successMsg, setSuccessMsg] = useState("");

	useEffect(() => {
		if (resendRef.current) {
			setRecaptcha(new firebase.auth.RecaptchaVerifier('resend-button', {
				'size': 'invisible',
				'callback': (response) => {
					// console.log(response);
				}
			}));
		}
	}, [resendRef]);

	const doReSend = () => {
		setLoading(true);
		auth.signInWithPhoneNumber(phoneNumber, recaptcha)
			.then((confirmationResult) => {
				dispatch(setphoneConfirm(confirmationResult));
				setSuccessMsg("RESEND_SUCCESSFULLY");
				setLoading(false);
				setCountdown(60);
			}).catch((error) => {
				errorHandle(error.code);
				setLoading(false);
			})
		// .finally(() => console.log('finally'))
	}

	useEffect(() => {
		if (successMsg !== '') {
			const timeout = setTimeout(() => setSuccessMsg(""), 10000);
			return () => clearInterval(timeout);
		}
	}, [successMsg]);


	const [countdown, setCountdown] = useState(60);

	useEffect(() => {
		const timer = setInterval(() => {
			if (countdown >= 1) {
				setCountdown(countdown - 1);
			} else {
				clearInterval(timer);
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [countdown]);

	return (
		<>
			<div className={pageLoading ? 'visually-hidden' : ''}>
				<div className="position-absolute" style={{ top: 16, left: 16 }}>
					<button className="btn btn-weoll-link-light p-1" onClick={() => dispatch(setCurrentScreen('smsLogin'))}>
						<img src={ArrowLeft} alt={t('BACK')} style={{ width: 32, height: 32 }} />
					</button>
				</div>

				<div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center gap-4">
					<div className="d-flex flex-column gap-1 text-center">
						<HeaderText>{t('ENTER_SMS_CODE')}</HeaderText>
						<SubHeaderText>
							<Trans
								i18nKey="SMS_VERIFICATION_INFO_MSG"
								components={{
									1: <br />
								}}
							>
							</Trans>
						</SubHeaderText>
					</div>

					<div className="d-flex flex-column gap-2">
						<Formik
							innerRef={formRef}
							enableReinitialize
							initialValues={{
								pin: ''
							}}
							validationSchema={Yup.object({
								pin: Yup.string()
									.min(6, 'REQUIRED_FIELD')
									.max(6, 'REQUIRED_FIELD')
									.required('REQUIRED_FIELD'),
							})}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(true);

								const SignInWithPhoneEvent = new CustomEvent("phoneSignIn", {
									detail: {
										pin: values.pin,
										confirmResult: phoneConfirm,
									},
								});

								window.dispatchEvent(SignInWithPhoneEvent);
								setSubmitting(false);
							}}
						>
							{({ setValues, values }) => (
								<Form>
									<div className="d-flex flex-column gap-2">
										<VerifyInput
											name="pin"
											onChange={(e) => setValues({ ...values, pin: e })}
										/>
										<Button variant="weoll-primary" type="submit" style={{ width: 294 }}>{t('CONTINUE')}</Button>
										<Button
											id="resend-button"
											ref={resendRef}
											disabled={countdown !== 0}
											variant="weoll-link-light"
											onClick={() => doReSend()}
											className={`d-flex align-self-end ${loading && 'viasually-hidden'}`}
										>
											<div className="d-flex gap-2 align-items-center justify-content-center">
												<img src={ResendIcon} alt={t('RESEND_CODE')} style={{ width: 18 }} />
												<div>{t('RESEND_CODE')}</div>
												{countdown ? <div style={{width: 24}}>{1 <= countdown ? '(' + countdown + ')' : ''}</div> : ''}
											</div>
										</Button>
										<WeollAlert
											status="success"
											message={successMsg}
											values={{
												phoneNumber: phoneNumber
													? phoneNumber.slice(0, 4) + phoneNumber.slice(4, 11).replace(/[0-9#]/g, '*') + phoneNumber.slice(11, 13)
													: null
											}}
										/>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
			{pageLoading ? <Loading subText={t('JUST_A_FEW_SECONDS')} emoji /> : <div />}
		</>
	);
}

export default SmsVerification;
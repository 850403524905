/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Login from "./layout/login";
import LangChangeHandler from './components/weoll-language-switch';
import IAP from "./core/actions/setIAP";
import MainLayout from "./layout/mainLayout";

const Welcome = lazy(() => import('./layout/welcome'));

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const localDefaultLng = localStorage.getItem('language');
    const urlParams = new URLSearchParams(window.location.search);
    // burada bize parametre geldikten sonra ? kalkmalı. şu an için yine locale yazılacak. 
    const lang = urlParams?.get('lang');
    if (lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('language', lang);
    } else {
      if (localDefaultLng && localDefaultLng === 'tr') {
        i18n.changeLanguage('tr');
        localStorage.setItem('language', 'tr');
      } else {
        i18n.changeLanguage('en');
        localStorage.setItem('language', 'en');
      }
    }
  }, []);

  return (
    <>
      <LangChangeHandler />
      <IAP />
      <MainLayout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MainLayout>
    </>
  );
}

export default App;

import React from 'react';

function SignInButton({ click, icon, children }) {
  return (
    <button className="weoll-sign-btn" onClick={() => click()}>
      <div className="d-flex align-items-center w-100 gap-1">
        <img src={icon} alt="logo" width={22} height={22} />
        <div className="flex-grow-1">
          {children}
        </div>
      </div>
    </button>
  );
}

export default SignInButton;
import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import 'intl-tel-input/build/css/intlTelInput.css';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import firebase from 'firebase/compat/app';
import Button from 'react-bootstrap/Button';
import { setCurrentScreen, setphoneConfirm, setPhoneNumber } from '../../core/slice';
import Loading from '../loading';
import PhoneInput from './subComponents/phoneInput';
import HeaderText from '../../components/headerText';
import SubHeaderText from '../../components/subHeaderText';
import arrowLeft from '../../assets/images/icons/arrowLeft.svg';
import errorHandle from '../../utilities/errorHandle';


function SmsLogin() {
	const dispatch = useDispatch();
	const auth = (useSelector((state) => state.projectData.auth));
	const { t } = useTranslation();
	const formRef = useRef(null);
	const [loading, setLoading] = useState(false);

	const rePhoneNumber = /([(]?)([5])([0-9]{2})([)]?)([\s]?)([0-9]{3})([\s]?)([0-9]{2})([\s]?)([0-9]{2})$/g;

	return (
		<>
			<div className={loading ? 'visually-hidden' : ''}>
				<div className="position-absolute" style={{ top: 16, left: 16 }}>
					<button className="btn btn-weoll-link-light p-1" onClick={() => dispatch(setCurrentScreen('signInButtons'))}>
						<img src={arrowLeft} alt={t('BACK')} style={{ width: 32, height: 32 }} />
					</button>
				</div>
				<div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center gap-4">
					<div className="d-flex flex-column gap-1 text-center">
						<HeaderText>{t('ENTER_YOUR_PHONE_NUMBER')}</HeaderText>
						<SubHeaderText>
							<Trans
								i18nKey="SMS_LOGIN_INFO_MSG"
								values={{ weoll: 'Weoll' }}
								components={{
									1: <span className="text-center" style={{ fontWeight: '500' }}></span>,
									2: <br />
								}}
							>
							</Trans>
						</SubHeaderText>
					</div>
					<div className="d-flex flex-column gap-2">
						<Formik
							innerRef={formRef}
							enableReinitialize
							initialValues={{
								phone: ''
							}}
							validationSchema={Yup.object({
								phone: Yup.string()
									.max(13, 'LESS_13')
									.required('REQUIRED_FIELD')
									.matches(rePhoneNumber, 'INVALID_PHONE_NUMBER')
							})}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(true);

								let phoneNumber = values.phone.replace(/\s+/g, '');

								if (phoneNumber.startsWith('5') || (!phoneNumber.startsWith('+90') && !phoneNumber.startsWith('0'))) {
									phoneNumber = `+90${phoneNumber}`;
								}

								if (phoneNumber.startsWith('0')) {
									phoneNumber = `+9${phoneNumber}`;
								}

								setLoading(true);
								const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-number', {
									'size': 'invisible',
									'callback': (response) => {
										// console.log(response);
										// reCAPTCHA doğrulandı. signInWithPhoneNumber fonksiyonunu burada çağırabilirsiniz
									}
								});
								dispatch(setPhoneNumber(phoneNumber));
								auth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
									.then((confirmationResult) => {
										dispatch(setphoneConfirm(confirmationResult));
										dispatch(setCurrentScreen('smsVerification'));
										setLoading(false);
									}).catch((error) => {
										errorHandle(error.code);
										setLoading(false);
									});
								setSubmitting(false);
							}}
						>
							<Form>
								<PhoneInput
									name="phone"
									placeholder="5__ ___ __ __"
								/>
							</Form>
						</Formik>
						<Button
							id="phone-number"
							type="button"
							className={`btn btn-weoll-primary ${loading && 'visually-hidden'}`}
							style={{ width: 294 }}
							onClick={() => formRef.current.handleSubmit()}
						>
							{t('CONTINUE')}
						</Button>
					</div>
				</div>
			</div>
			{loading && (
				<Loading subText={t('JUST_A_FEW_SECONDS')} emoji />
			)}
		</>
	);
}

export default SmsLogin;

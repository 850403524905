
import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert'
import mdiCheckCircle from '../../assets/images/icons/mdiCheckCircle.svg';
import mdiAlertCircle from '../../assets/images/icons/mdiAlertCircle.svg';
import { Trans, useTranslation } from 'react-i18next';

function WeollAlert({ status, message, values }) {
	const { t } = useTranslation();
	return (
		<div className={message ? "" : "visually-hidden"} style={{ maxWidth: 264, margin: "auto" }}>
			<Alert variant={`weoll-${status}`} style={{ padding: '4px 8px', margin: 0, borderRadius: 8 }}>
				<div className={`d-flex align-items-${t(message).length > 30 ? "start" : "center"} text-start justify-content-center gap-1`}>
					{status === 'success' && (
						<img src={mdiCheckCircle} style={{ width: 20 }} alt={t('SUCCESS')} />
					)}
					{status === 'error' && (
						<img src={mdiAlertCircle} style={{ width: 20 }} alt={t('ERROR')} />
					)}
					<span style={{ fontSize: 13 }}>
						{values ? <Trans values={{ ...values }} i18nKey={message} /> : t(message)}
					</span>
				</div>
			</Alert>
		</div>
	);
}

WeollAlert.defaultProps = {
	message: "",
};

WeollAlert.propTypes = {
	message: PropTypes.string,
	status: PropTypes.string.isRequired,
};

export default WeollAlert;
